import React from "react"

const AnimatedLogo = (props: React.SVGProps<SVGSVGElement>) => {
  const { children, ...rest } = props
  return (
    <svg
      //   enableBackground="new 0 0 841.89 595.28"
      viewBox="0 0 20 333"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {children}
      <g clipPath="url(#clip0_722_108)">
        <rect width="20" height="333" fill="white" />
        <path d="M0 50.6602H20V333.15H0V50.6602Z" fill="white" />
        <path className="animate-scaleY" d="M0 0H20V257H0V0Z" fill="#708C7D" />
      </g>
      <defs>
        <clipPath id="clip0_722_108">
          <rect width="20" height="333" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

const Monogram = (props: React.SVGProps<SVGSVGElement>) => {
  const { children, ...rest } = props
  return (
    <svg
      //   enableBackground="new 0 0 841.89 595.28"
      viewBox="0 0 544 200"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {children}
      <path
        d="M93.7803 100.86L31.0903 4.03996H0.570312V196.57H33.5703V67.32L93.7803 158.91L154.01 67.32V196.59H187.02V4.04997H156.5L93.7803 100.86ZM245.76 157.33C233.89 157.33 224.46 166.76 224.46 178.61C224.46 190.47 233.89 199.9 245.76 199.9C257.61 199.9 267.04 190.47 267.04 178.61C267.04 166.77 257.61 157.33 245.76 157.33ZM521.88 157.33C510.01 157.33 500.58 166.76 500.58 178.61C500.58 190.47 510.01 199.9 521.88 199.9C533.73 199.9 543.16 190.47 543.16 178.61C543.16 166.77 533.72 157.33 521.88 157.33ZM391.22 169.09C353.33 169.09 327.26 137.45 327.26 100.31C327.26 63.17 353.33 31.54 391.22 31.54C422.79 31.54 440.09 52.72 440.09 52.72L462.33 30.98C462.33 30.98 437.34 0.719971 391.22 0.719971C335.76 0.719971 293.75 43.37 293.75 100.31C293.75 157.26 335.75 199.9 391.22 199.9C439.82 199.9 465.08 167.16 465.08 167.16L443.11 145.42C443.1 145.43 425 169.09 391.22 169.09V169.09Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { Monogram, AnimatedLogo }
