import React from "react"
import { useCursorPosition } from "../lib/useCursorPosition"
import { useWindow } from "../lib/useWindow"

const Cursor = ({ primaryInput }: any) => {
  // Let elements request the cursor be displayed via class
  const window = useWindow(),
    pos = useCursorPosition(window, primaryInput),
    requestsCursor = pos?.target?.classList?.contains(`cursor`),
    isLink = [`A`, `BUTTON`].indexOf(pos?.target?.tagName) > -1,
    isDark = pos?.target?.matches(`[data-theme="dark"],[data-theme="dark"] *`),
    clickable = requestsCursor || isLink

  return (
    <div
      className={`${pos.x < 0 || pos.y < 0 ? `hidden` : ``} touch:hidden fixed pointer-events-none z-50 block w-3 h-3 -translate-y-1/2 -translate-x-1/2 rotate-45`}
      style={{ left: pos.x, top: pos.y }}
    >
      <div
        className={`w-full h-full ${clickable ? `border-white bg-white rounded-[calc(0.75em/2)]` : isDark ? `border-brand-400 bg-brand-400` : `border-brand bg-brand`} border transition-all duration-300`}
      />
    </div>
  )
}

export { Cursor }