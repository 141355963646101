module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-M7JXVRTE8Q"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"MC5ZdGV4UHhBQUFDQUFvaVRR.77-9Ju-_ve-_ve-_vR3vv73vv73vv73vv71MIe-_vQFych3vv71KYO-_ve-_vT1EDjzvv71077-9Gm7vv70 (edited)","imageImgixParams":{"auto":"format","dpr":1,"fit":"max","q":50},"imagePlaceholderImgixParams":{"w":2,"q":2},"lang":"*","repositoryName":"matchmadeco","promptForAccessToken":true,"apiEndpoint":"https://matchmadeco.cdn.prismic.io/api/v2","pageSize":100,"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Full Name","short_name":"Name","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"71a50dbba44c78128b221b7df7bb51f1"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
