import React from "react"
import { Helmet } from "react-helmet"
import { usePrismicSettings } from "../../lib/usePrismicSettings"

type Props = {
  title?: string
  useTitleTemplate?: boolean
  description?: string
  ogImage?: string
  pathname?: string
}

const SEO = ({
  title,
  useTitleTemplate,
  description,
  ogImage,
  pathname,
}: Props) => {
  const {
      data: { prismicSettings },
    } = usePrismicSettings(),
    seo = {
      title: title ?? prismicSettings?.data?.title,
      titleTemplate: useTitleTemplate
        ? prismicSettings?.data?.title
          ? `%s | ${prismicSettings?.data?.title}`
          : undefined
        : undefined,
      description: description ?? prismicSettings?.data?.description,
      image: ogImage ? ogImage : `${prismicSettings?.data?.image?.open_graph}`,
      url: `${prismicSettings?.data?.site_url}${pathname ?? ""}`,
    }

  return (
    <Helmet
      title={seo.title as string}
      titleTemplate={seo.titleTemplate}
      htmlAttributes={{
        lang: "en",
      }}
    >
      {seo.description && <meta name="description" content={seo.description} />}

      {seo.image && <meta name="image" content={seo.image} />}

      {/* Open Graph */}
      <meta property="og:title" content={seo.title as string} />
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content="website" />
    </Helmet>
  )
}

export default SEO
export { SEO }
