import React, { useState, useRef } from "react"
import { PrismicLink } from "@prismicio/react"
import { Transition } from "@headlessui/react"
import { Monogram } from "./svg"
import useEventListener from "../lib/useEventListener"
import { useWindow } from "../lib/useWindow"

type Props = {
  className?: string
  hideLegal?: boolean
}

const Footer = ({ className, hideLegal }: Props) => {
  const year = new Date().getFullYear(),
    window = useWindow(),
    [pinned, setPinned] = useState(true),
    scrollTop = useRef(
      typeof document !== `undefined`
        ? document?.scrollingElement?.scrollTop ?? 0
        : 0
    ),
    lastPinned = useRef(
      typeof document !== `undefined`
        ? document?.scrollingElement?.scrollTop ?? 0
        : 0
    ),
    scrollHandler = () => {        
      const newScrollTop = document?.scrollingElement?.scrollTop

      if(typeof newScrollTop !== `number`) return

      const scrollChange = newScrollTop - (scrollTop.current ?? 0)

      // Update last pin reference whenever scollChange is < 0
      if (scrollChange < 0) lastPinned.current = newScrollTop

      // If newScrollTop is > 100 from last pin reference, unpin the navigation
      if (newScrollTop - lastPinned.current > 100 && pinned) setPinned(false)

      // If scrollChange is < 0, pin the navigation
      if (scrollChange < 0 && !pinned) setPinned(true)

      // Store newScrollTop
      scrollTop.current = newScrollTop ?? scrollTop.current
    }

  useEventListener(`scroll`, scrollHandler, window, { passive: true })

  return (
    <footer className={`fixed pointer-events-none inset-x-0 bottom-0 z-20 flex justify-between items-end p-8 pb-12 sm:pb-8 ${className} ${pinned ? `opacity-100` : `opacity-0`} sm:opacity-100 transition-[colors, opacity] duration-500`}>
      {/* Brand Logo */}
      <PrismicLink className={`${pinned ? `pointer-events-auto` : `pointer-events-none`} sm:pointer-events-auto`} href="/">
        <div className="pointer-events-none">
          <span className="sr-only">Matchmade Company</span>
          <Monogram aria-hidden="true" className="w-[88.5px] sm:w-[177px]" />
        </div>
      </PrismicLink>

      {/* Legal */}
      <Transition
        show={!hideLegal}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <ul className="flex flex-col sm:flex-row sm:space-x-10 space-y-1 sm:space-y-0 text-right sm:text-left text-xs sm:text-15px">
          {/* Copyright */}
          <li>
            <span className="-mr-1 p-1 sm:-m-3 sm:p-3 uppercase">
              © {year} MMCO
            </span>
          </li>
          {/* Privacy policy */}
          {/* {data.prismicSettings?.data.privacy && ( */}
          {/* {(
            <li className="hidden sm:block">
              <PrismicLink
                href="/privacy"
                className={`${pinned ? `pointer-events-auto` : `pointer-events-none`} sm:pointer-events-auto -mr-1 p-1 sm:-m-3 sm:p-3 uppercase text-brand hocus:text-brand-400 transition-colors duration-500`}
              >
                Privacy policy
              </PrismicLink>
            </li>
          )} */}
        </ul>
      </Transition>
    </footer>
  )
}

export default Footer
export { Footer }
