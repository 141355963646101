import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

const settingsQuery = graphql`
  query Settings {
    prismicSettings {
      _previewable
      data {
        email
        press
        title
        description
        site_url
        image {
          open_graph: url(imgixParams: { w: 1200, h: 630, fit: "crop" })
        }
        facebook
        instagram
        name
        phone
        twitter
        # Address
        street_address
        address_locality
        address_region
        address_region_full
        postal_code
        address_country
      }
    }
  }
`

export const usePrismicSettings = () => {
  const staticData = useStaticQuery<Queries.SettingsQuery>(settingsQuery),
    { data, isPreview } = useMergePrismicPreviewData(staticData)

  return { data, isPreview }
}
