/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

// You can delete this file if you're not using it

import { PrismicProvider } from "@prismicio/react"
import { GatsbySSR, Link } from "gatsby"
import {
  componentResolverFromMap,
  PrismicPreviewProvider,
} from "gatsby-plugin-prismic-previews"
import * as React from "react"
import { Layout } from "./src/components/layout"
import { linkResolver } from "./src/utilities/linkResolver"

export const wrapPageElement: GatsbySSR["wrapPageElement"] = ({
  element,
  props,
}) => <Layout {...props}>{element}</Layout>

export const wrapRootElement: GatsbySSR["wrapRootElement"] = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY ?? ``,
        linkResolver,
        componentResolver: componentResolverFromMap({
          // component_type: ComponentTemplate,
        }),
      },
    ]}
  >
    <PrismicProvider
      linkResolver={linkResolver}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      {element}
    </PrismicProvider>
  </PrismicPreviewProvider>
)
