import { Popover, Transition, FocusTrap } from "@headlessui/react"
import { PrismicLink } from "@prismicio/react"
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import React, { useMemo, useRef } from "react"
import { usePrismicSettings } from "../lib/usePrismicSettings"
import { Monogram } from "./svg"

export const Menu = ({ className }: { className?: string }) => {
  const { data } = usePrismicSettings()
  const closeRef = useRef<HTMLButtonElement>(null)

  const settingsQuery = graphql`
    query Menus {
      allPrismicMenu {
        nodes {
          _previewable
          data {
            links {
              link_text
              submenu {
                id
              }
              link {
                ...LinkFragment
              }
            }
          }
          prismicId
        }
      }
    }
  `

  const staticData = useStaticQuery<Queries.MenusQuery>(settingsQuery),
    { data: menuData, isPreview } = useMergePrismicPreviewData(staticData)

  // find the top menu
  const mainMenu = useMemo(
    () =>
      menuData.allPrismicMenu.nodes.find((menu) => {
        // search if this menu is the submenu of some menu
        return !menuData.allPrismicMenu.nodes.some((menu2) =>
          menu2.data.links?.some((link) => {
            return menu.prismicId === link?.submenu?.id
          })
        )
      }),
    [menuData]
  )

  const renderMenu = (menu: any, depth = 0) => (
    <>
      {menu.data.links.map((link: any, i) => {
        const linkChildren = (
          <>
            <span className="relative pointer-events-none">
              {link.link_text}
              <span
                aria-hidden="true"
                className="absolute top-1/2 -left-3.5 h-2.5 w-2.5 -translate-y-1/2 rounded-full bg-white opacity-0 transition-opacity group-hover:opacity-100 group-focus-visible:opacity-100"
              ></span>
            </span>
            {link.submenu?.id && (
              <ul className="flex sm:hidden sm:parent-hover:flex sm:parent-focus:flex sm:parent-focus-within:flex flex-col">
                {renderMenu(
                  menuData.allPrismicMenu.nodes.find(
                    (menu) => menu.prismicId === link.submenu.id
                  ),
                  depth + 1
                )}
              </ul>
            )}
          </>
        )

        const linkTextClassNames = depth > 0 ? `text-brand-400 hocus:text-white` : `text-white hocus:text-white/50`,
          linkClassNames = `${linkTextClassNames} inline-block w-max ${link.submenu?.id ? `focus:outline-none focus-visible:ring focus-visible:ring-sand` : `group`} relative -m-0.5 p-0.5 transition-colors`
          

        const linkWrapper = link.link.url ? (
          <Popover.Button
            as={PrismicLink}
            className={linkClassNames}
            field={link.link}
            key={i}
          >
            {linkChildren}
          </Popover.Button>
        ) : (
          <div
            className={linkClassNames}
            key={i}
            tabIndex={0}
          >
            {linkChildren}
          </div>
        )

        return linkWrapper
      })}
    </>
  )

  return (
    <Popover>
      {({ open }) => (
        <>
          {/* Open Button */}
          <Popover.Button className={`fixed z-20 top-14 sm:top-8 left-8 -m-2 inline-flex p-2 ${className} transition-colors duration-500`}>
            <div className="pointer-events-none">
              <span className="sr-only">Open Menu</span>
              {/* Mobile hamburger */}
              <svg aria-hidden="true" className="sm:hidden stroke-current" height="16" viewBox="0 0 20 16" width="20" xmlns="http://www.w3.org/2000/svg">
                <g data-name="Hamburger Menu" strokeWidth="2" transform="translate(0 1)">
                  <path d="m0 0h20" />
                  <path d="m0 7h20" />
                  <path d="m0 14h20" />
                </g>
              </svg>
              {/* Desktop hamburger */}
              <svg aria-hidden="true" className="hidden sm:block stroke-current" width="58" height="22.171">
                <g data-name="Hamburger Menu" strokeWidth="2">
                  <path d="M0 1h58" />
                  <path d="M0 11.085h58" />
                  <path d="M0 21.171h58" />
                </g>
              </svg>
            </div>
          </Popover.Button>
          <Transition
            as={React.Fragment}
            enter="ease duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            show={open}
          >
            <Popover.Overlay className="fixed z-30 inset-0 bg-sand bg-opacity-60 cursor" />
          </Transition>
          <Transition
            enter="transition duration-700"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition duration-300"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
            as={React.Fragment}
            unmount={false}
          >
            <Popover.Panel
              className="fixed inset-y-0 left-0 z-40 h-full w-full sm:w-1/2"
              data-theme="dark"
              static={true}
            >
              <FocusTrap
                initialFocus={closeRef}
                // HeadlessUI FocusTrap features are decoded as a bit string, see https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-react/src/components/focus-trap/focus-trap.tsx#L28 for options
                features={parseInt("10110", 2)}
                className="flex h-full flex-col items-start justify-between bg-brand p-8 pt-14 sm:pt-8 pb-12 sm:pb-8 text-white transition-transform duration-500 will-change-transform"
              >
                <Popover.Button
                  ref={closeRef}
                  className="-m-2 inline-flex p-2 text-white hocus:text-white/50 transition-colors duration-500"
                >
                  <div className="pointer-events-none">
                    <span className="sr-only">Close Menu</span>
                    {/* Mobile close */}
                    <svg
                      aria-hidden="true"
                      className="sm:hidden"
                      height="16.023"
                      viewBox="0 0 16.023 16.023"
                      width="16.023"
                      >
                      <g stroke="currentColor" strokeWidth="2">
                        <path d="m.707.707 14.609 14.609"/>
                        <path d="m15.316.707-14.609 14.609"/>
                      </g>
                    </svg>
                    {/* Desktop close */}
                    <svg
                      aria-hidden="true"
                      className="hidden sm:block"
                      height="26.73"
                      width="26.73"
                    >
                      <g stroke="currentColor" strokeWidth="3">
                        <path d="M1.061 1.061 25.67 25.67" />
                        <path d="M25.67 1.061 1.061 25.67" />
                      </g>
                    </svg>
                  </div>
                </Popover.Button>

                {/* Navigation */}
                <nav
                  aria-label="Main navigation"
                  className="absolute top-0 w-full mt-48 sm:mt-[20vw]"
                >
                  <ul className="flex flex-col font-serif text-[20px] leading-[21.81px] sm:text-22px sm:leading-6 tracking-[0.05em] uppercase">
                    {renderMenu(mainMenu)}
                  </ul>
                </nav>

                <div className="flex w-full items-end justify-between">
                  {/* Brand Logo */}
                  <Popover.Button as={PrismicLink} href="/">
                    <div className="pointer-events-none">
                      <span className="sr-only">Matchmade Company</span>
                      <Monogram aria-hidden="true" className="w-[88.5px] sm:w-[177px]" />
                    </div>
                  </Popover.Button>

                  {/* Socials */}
                  <ul className="flex flex-col sm:flex-row sm:space-x-10 space-y-1 sm:space-y-0 text-right sm:text-left text-xs sm:text-15px text-white">
                    {/* Twitter */}
                    {data.prismicSettings?.data.twitter && (
                      <li>
                        <PrismicLink
                          href={`https://twitter.com/${data.prismicSettings?.data.twitter.replace(
                            /^@/,
                            ""
                          )}`}
                          className="-mr-1 p-1 sm:-m-3 sm:p-3 uppercase hocus:text-white/50 transition-colors duration-500"
                        >
                          Twitter
                        </PrismicLink>
                      </li>
                    )}
                    {/* Facebook */}
                    {data.prismicSettings?.data.facebook && (
                      <li>
                        <PrismicLink
                          href={`https://www.facebook.com/${data.prismicSettings?.data.facebook.replace(
                            /^@/,
                            ""
                          )}`}
                          className="-mr-1 p-1 sm:-m-3 sm:p-3 uppercase hocus:text-white/50 transition-colors duration-500"
                        >
                          Facebook
                        </PrismicLink>
                      </li>
                    )}
                    {/* Instagram */}
                    {data.prismicSettings?.data.instagram && (
                      <li>
                        <PrismicLink
                          href={`https://www.instagram.com/${data.prismicSettings?.data.instagram.replace(
                            /^@/,
                            ""
                          )}`}
                          className="-mr-1 p-1 sm:-m-3 sm:p-3 uppercase hocus:text-white/50 transition-colors duration-500"
                        >
                          Instagram
                        </PrismicLink>
                      </li>
                    )}
                  </ul>
                </div>
              </FocusTrap>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
