import { useEffect, useRef } from "react"

const useWindow = (): Record<string, never> | Window => {
  const windowObject = useRef({})

  useEffect(() => {
    windowObject.current = window
  }, [])

  return windowObject.current
}

export { useWindow }