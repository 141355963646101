import { LinkResolverFunction } from "@prismicio/helpers"

/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 *
 * @type import('@prismicio/helpers').LinkResolverFunction
 */
const linkResolver: LinkResolverFunction<string> = (doc) => {
  switch (doc.type) {
    case "journal_entry":
      return `/journal`
    case "page":
      return doc.uid !== `index` ? `/${doc.uid}` : `/`
    case "menu":
    case "settings":
      return `/`
    default:
      return `/${doc.type}s/${doc.uid}`
  }
}

export default linkResolver
export { linkResolver }
