import React, { ReactElement, useEffect, useState } from "react"
import { PageProps } from "gatsby"
import { Helmet } from "react-helmet"
import { primaryInput } from "detect-it"
import { Cursor } from "./cursor"
import { Menu } from "./menu"
import { Transition } from "@headlessui/react"
import { AnimatedLogo } from "./svg"
import Footer from "./footer"
import { SEO } from "./seo"

const Layout = ({ children, location, pageContext, path, ...rest }: PageProps) => {
  const type = pageContext?.type ?? ``

  // get the meta information slice
  const meta = pageContext.data?.body?.find(
    (slice: any) => slice.slice_type === `meta`
  )

  const { title, description, image } = Object.assign({}, { title: pageContext.data?.title }, meta?.primary ?? {})

  const [showSplash, setShowSplash] = useState(true)

  const [conceptGalleryState, setConceptGalleryState] = useState({
    activeIndex: 0,
    activeConcept: ``
  })

  // Determine if the rendered content contains a Concept Gallery slice
  const hasConceptGallery = children?.props?.data?.prismicPage?.data?.body?.find(slice => slice?.slice_type === `concept_gallery`)

  useEffect(() => {
    setTimeout(() => {
      setShowSplash(false)
    }, 1800)
  }, [])

  return (
    // Use full height to keep the footer at the bottom if there is not enough content to fill the space
    <div className="flex min-h-full flex-col items-start">
      <Helmet>
        <html
          className={`${primaryInput} ${type} [font-feature-settings:'ss01']`}
        />
      </Helmet>
      {/* SEO */}
      <SEO
        title={title}
        // Use template for all content but the homepgae
        useTitleTemplate={location.pathname !== `/`}
        description={description}
        ogImage={image?.url}
        pathname={location.pathname}
      />
      {/* Splash */}
      <Transition
        show={showSplash}
        as={React.Fragment}
        leave="duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed z-50 inset-0 bg-brand-400 flex items-center justify-center">
          <AnimatedLogo className="h-[356px] w-[21.37px]" />
        </div>
      </Transition>
      {/* Cursor */}
      <Cursor primaryInput={primaryInput} />
      {/* Navigation */}
      <Menu
        className={hasConceptGallery ? `text-white` : `text-brand`}
      />
      {/* Children */}
      <div className="flex-1 sm:w-[60vw] mx-8 sm:mx-auto">
        {React.cloneElement(
          children as ReactElement, {
            conceptGalleryState,
            setConceptGalleryState
          }
        )}
      </div>
      {/* Footer */}
      <Footer
        className={hasConceptGallery ? `text-white` : `text-brand`}
        hideLegal={hasConceptGallery}
      />
    </div>
  )
}

export default Layout
export { Layout }
