import { useState, useEffect } from "react"

const useCursorPosition = (element, primaryInput) => {
  const [pos, setPos] = useState({
    x: -1,
    y: -1,
    relX: 0,
    relY: 0,
    target: null,
    parent: {},
  })

  const setMousePos = (e) => {
    setPos({
      x: e.x,
      y: e.y,
      relX: e.offsetX,
      relY: e.offsetY,
      target: e.target,
      parent: e.target.parentElement,
    })
  }

  const setMouseLeave = (e) => {
    setPos({
      x: -1,
      y: -1,
      relX: 0,
      relY: 0,
      target: e.target,
      parent: e.target.parentElement,
    })
  }

  useEffect(() => {
    if(typeof element?.addEventListener !== `function`) return
    if(primaryInput !== `mouse`) return

    if (element) {
      element.addEventListener(`mousemove`, setMousePos, { passive: true })
      element.addEventListener(`mouseleave`, setMouseLeave, { passive: true })
    }

    return () => {
      if (element) {
        element.removeEventListener(`mousemove`, setMousePos, { passive: true })
        element.removeEventListener(`mouseleave`, setMouseLeave, { passive: true })
      }
    }
  }, [element])

  return pos
}

export { useCursorPosition }